'use strict';

document.addEventListener("DOMContentLoaded", function () {
    var instances = [];
    $('[data-favourite-menu-btn]').each(function () {
        instances.push(new FavouriteMenuBtn(this));
    })
});

var counter = document.querySelector('[data-favourite-menu__count]');

function FavouriteMenuBtn(elem) {
    this.elem = elem;
    var self = this;
    var counterElm = elem.querySelector('[data-favourite-menu-btn__count]');



    function initialize() {
        if (!window.favouritesService) {
            console.log('unable to initialize favourites btn', elem);
            return;
        }

        window.favouritesService.getTotalFavourites(function (result) {
            updateCounter(result);
        });

        window.favouritesService.addOnChangeHandler(function () {
            window.favouritesService.getTotalFavourites(function (result) {
                updateCounter(result);
            });
        });

        self.elem.addEventListener('click', function () {
            window.favouritesMenu.toggle();
        })
    }
    initialize();


    function updateCounter(count) {
        counterElm.innerText = count;
        counter.innerText = count;

        if (count == 1) {
            document.querySelector('.countMethods').style.display = 'none';
            document.querySelector('.countMethod').style.display = 'unset';
        }
        else {
            document.querySelector('.countMethods').style.display = 'unset';
            document.querySelector('.countMethod').style.display = 'none';
        }
    }
}
